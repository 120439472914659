@font-face {
	font-family:'BPGMrgvlovani';
	src: url('./fonts/BPGMrgvlovani.eot');
	src: url('./fonts/BPGMrgvlovani.eot?#iefix') format('embedded-opentype'),
		url('./fonts/BPGMrgvlovani.woff') format('woff'),
		url('./fonts/BPGMrgvlovani.ttf') format('truetype'),
		url('./fonts/BPGMrgvlovani.svg#BPGMrgvlovani-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FFFD;
}

@font-face {
	font-family:'BPGMrgvlovaniCaps';
	src: url('./fonts/BPGMrgvlovaniCaps2010.eot');
	src: url('./fonts/BPGMrgvlovaniCaps2010.eot?#iefix') format('embedded-opentype'),
		url('./fonts/BPGMrgvlovaniCaps2010.woff') format('woff'),
		url('./fonts/BPGMrgvlovaniCaps2010.ttf') format('truetype'),
		url('./fonts/BPGMrgvlovaniCaps2010.svg#BPGMrgvlovaniCaps2010-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FFFD;
}

.jumbotron {
    margin-top: 10px;
}

.jumbotron .h1, .jumbotron h1 {
    font-family:'BPGMrgvlovaniCaps';
    font-size: 36px;
}

.jumbotron p {
    font-family:'BPGMrgvlovani';
    font-size: 15px;
}

.nav a:not(.glyphicon) {
    font-family:'BPGMrgvlovaniCaps';
    font-size: 13px;
}


.wrapper {
    min-height: 500px;
}


.select2-container .select2-selection--single {
	height: 34px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 34px;
}

.home-logo {
	margin: 30px 0;
	width: 200px;
}

.form-group .checkbox label,
.form-group .radio label {
	margin-right: 10px;
}

*:focus,
*:active,
button:focus {
	outline: none !important;
}
